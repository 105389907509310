import { FeatureFlags } from "@olivahealth/utils/types/FeatureFlags";

export const ENVIRONMENT =
  (process.env.VERCEL_ENV ||
    process.env.ENVIRONMENT ||
    process.env.NEXT_PUBLIC_VERCEL_ENV ||
    process.env.LAMBDA_ENVIRONMENT) ??
  "local";

// removing this might cause the BE build to fail
export const APP_VERSION = process.env.APP_VERSION || "";

export const VALID_EMAIL_REGEXP =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const VALID_PASSWORD_REGEXP =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d\ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}/;
export const VALID_PHONE_NUMBER_REGEXP =
  /^(?:\+[1-9]\d{0,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?|\(\d{1,3}\)[-.\s]?)\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/g;
export const VALID_URL_REGEXP =
  /(https:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
export const VALID_URL_LOOSE_REGEXP =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
export const VALID_ZIP_CODE_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const SLACK_APP_ID = "A01U1J8CUCR";
export const SLACK_APP_ID_DEV = "A02549ZUW4D";

// Cronofy
export const CRONOFY_CLIENT_ID =
  process.env.NEXT_PUBLIC_CRONOFY_CLIENT_ID ?? "";
export const CRONOFY_CLIENT_SECRET = process.env.CRONOFY_CLIENT_SECRET ?? "";

// Intercom
export const INTERCOM_APP_ID = "b7jzp71w";
export const INTERCOM_VERIFICATION_SECRET =
  process.env.INTERCOM_VERIFICATION_SECRET;

export const INTERCOM_URL = "https://api.intercom.io";
export const INTERCOM_FAQ_URL = "https://help.olivahealth.app";
export const INTERCOM_DEFAULT_POSITION = {
  verticalPadding: 20,
};

// Session management
export const DEFAULT_CANCELLATION_NOTICE_IN_DAYS = 2;
export const DEFAULT_CANCELLATION_NOTICE_IN_DAYS_NEW = 1;

// MFA
export const ROLES_REQUIRED_MFA = [
  "ADMIN",
  "CLINICAL_SUPERVISOR",
  "EXTERNAL_CLINICAL_SUPERVISOR",
  "MHP",
];

// See the responsibility of each Operation role in
// https://www.notion.so/olivahouse/Chat-feature-5ad223b4766c4ec0a5dccfe4bc309835#87f44a58dfe24be1a3e18b6e89a9951f
export const CARE_SUPPORT_ID = "care-navigator";
export const ASK_A_THERAPIST_ID_EN = "ask-a-therapist";
export const ASK_A_THERAPIST_ID_ES = "ask-a-therapist-es";
export const OLIVA_CUSTOMER_SUPPORT_ID = "oliva-customer-support";
export const CLINICAL_SUPERVISION_ID = "clinical-supervision";
export const reservedSendbirdIds = [
  CARE_SUPPORT_ID,
  ASK_A_THERAPIST_ID_EN,
  OLIVA_CUSTOMER_SUPPORT_ID,
  CLINICAL_SUPERVISION_ID,
  ASK_A_THERAPIST_ID_ES,
];

export const ReservedUserNickName = {
  CARE_SUPPORT_ID: "Care Support",
  OLIVA_CUSTOMER_SUPPORT_ID: "Oliva Customer Support",
  CLINICAL_SUPERVISION_ID: "Clinical Supervisor",
};

export const NOTIFICATION_EMAIL_FROM = "no-reply@oliva.health";
export const CARE_TEAM_EMAIL = "care@oliva.health";
export const DEV_TEAM_EMAIL = "dev@oliva.health";
export const CS_TEAM_EMAIL = "success@oliva.health";
export const OPS_EMAILS = ["abi@oliva.health", "andrea@oliva.health"];
export const SLACK_NOTIFICATIONS_WEBHOOK =
  "https://hooks.slack.com/services/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";

export const SLACK_NOTIFICATIONS_WEBHOOK_NPS_MHPS =
  "https://hooks.slack.com/services/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";
export const SLACK_NOTIFICATIONS_WEBHOOK_TRIALS =
  "https://hooks.slack.com/services/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";
export const SLACK_NOTIFICATIONS_WEBHOOK_NPS_EMPLOYEES =
  "https://hooks.slack.com/triggers/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";
export const SLACK_NOTIFICATIONS_WEBHOOK_WORKSHOP_REQUEST =
  "https://hooks.slack.com/triggers/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";

export const DEFAULT_CHAT_AVATAR =
  "https://firebasestorage.googleapis.com/v0/b/univers-de-l-oliva-dev.appspot.com/o/oliva-images%2Fimg_placeholder_user.svg?alt=media";
export const CDN_BUCKET_PROFESSIONALS_PHOTOS_URL =
  ENVIRONMENT === "production"
    ? "https://cdn.olivahealth.app/professional-photos/"
    : "https://cdn.staging.olivahealth.app/professional-photos/";
export const METABASE_SESSION_LOG_URL =
  "https://olivahealth.metabaseapp.com/question/7-sessions-log";

// Workshops/Classes
export const WORKSHOP_FEW_SPOTS_COUNT = 3;
export const WORKSHOP_LATE_JOINERS_GRACE_PERIOD_MINUTES = 5;
export const WORKSHOP_MINIMUM_AVAILABLE_SPOTS_WARNING_THRESHOLD = 5;
export const WORKSHOP_COST_IN_CREDITS = 1;
export const WORKSHOP_DELAY_MAX_BEFORE_FIRST_EVENT_TO_UPDATE_REQUEST = 60; // minutes

// Organisations/OrganisationMembers
export const ORGANISATION_MEMBER_EXPIRATION_DAYS = 28;

// Resources
export const RESOURCES_BUCKET_NAME = "oliva-resources";
export const SHARED_RESOURCES_BUCKET_NAME =
  ENVIRONMENT === "production" ? "oliva-resources" : "resources-staging";
export const SHARED_RESOURCES_BUCKET_REGION =
  ENVIRONMENT === "production" ? "eu-west-1" : "eu-west-3";
export const RESOURCE_PRESIGNED_URL_EXPIRATION_SECONDS = 2 * 3600; // 2 hours
export const RESOURCE_PROGRESS_TRACKING_INTERVAL_MS = 30 * 1000; // 30 seconds
export const NEW_RESOURCE_THRESHOLD_IN_DAYS = 14;
export const RESOURCE_CONSUMPTION_THRESHOLD_IN_SECONDS = 30;

export const SHARE_EXTERNAL_RESOURCE_FILE_MAX_SIZE = 2; // 2MB
export const SHARE_EXTERNAL_RESOURCE_FILE_ALLOWED_MIMETYPES = {
  "audio/*": [".mp3", ".ogg", ".wav"],
  "video/*": [".avi", ".mp4", ".mov", ".mpeg", ".webm"],
  "image/*": [".png", ".gif", ".jpeg", ".jpg"],
  "application/pdf": [".pdf"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/msword": [".doc"],
  "application/vnd.oasis.opendocument.text": [".odt"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
  "application/rtf": [".rtf"],
  "text/*": [".csv", ".txt"],
};

// Impersonation
export const IMPERSONATION_LOCAL_STORAGE_KEY = "oliva_impersonation";

// Users
export const CONTACT_DETAILS_GENERAL_PRACTITIONER_RELATIONSHIP =
  "General Practicioner";

// Wellbeing profile
export const MENTAL_HEALTH_PILLAR_NEEDS_FOCUS_SCORE_THRESHOLD = 65; // %

/**
 * Email templates
 * This enum-like constant holds all the aliases of the email templates on Postmark
 */
export const EMAIL_TEMPLATES = {
  ALL_PROFILES: {
    CHAT_MESSAGE_NEW: "chat-message-new",
    RESET_PASSWORD: "reset-password",
  },
  CUSTOMER_SUPPORT: {
    SESSION_ALLOCATION_THRESHOLD_REACHED:
      "cs-company-session-allocation-threshold-reached",
    WORKSHOP_REQUEST_RECEIVED: "cs-workshop-request-received",
    WORKSHOP_REQUEST_CANCELLED: "cs-workshop-request-cancelled",
    WORKSHOP_CANCELLED: "cs-workshop-cancelled",
  },
  BENEFITS_MANAGER: {
    SESSION_ALLOCATION_HALF_REACHED: "bm-session-allocation-half-reached",
    SESSION_ALLOCATION_SEVENTY_FIVE_PERCENT_REACHED:
      "bm-session-allocation-three-quarters-reached",
    SESSION_ALLOCATION_EIGHTY_FIVE_PERCENT_REACHED:
      "bm-session-allocation-eighty-five-percent-reached",
    SESSION_ALLOCATION_NINETY_FIVE_PERCENT_REACHED:
      "bm-session-allocation-ninety-five-percent-reached",
    SESSION_ALLOCATION_EXHAUSTED: "bm-session-allocation-exhausted",
    WORKSHOP_REQUEST_RECEIVED: "bm-workshop-request-received",
    WORKSHOP_REQUEST_CANCELLED: "bm-workshop-request-cancelled",
    WORKSHOP_CONFIRMED: "bm-workshop-confirmed",
    WORKSHOP_CANCELLED: "bm-workshop-cancelled",
  },
  EMPLOYEE: {
    SWITCHED_TO_PERSONAL_PLAN: "switched-to-personal-plan",
    CHAT_MESSAGES_PENDING: "employee-chat-messages-pending",
    WORKSHOP_CONFIRMED: "employee-workshop-confirmed",
    ONE_TO_ONE_SESSION_BOOKED: "employee-1-to-1-session-booked",
    REMOVED_ACCESS: "employee-removed-access-to-company-plan",
    ONE_TO_ONE_SESSION_BOOKED_BY_PROFESSIONAL:
      "employee-1-to-1-session-booked-by-professional",
    ONE_TO_ONE_SESSION_BOOKING_REMINDER: "employee-1-to-1-book-next-session",
    ONE_TO_ONE_SESSION_CANCELLED: "employee-1-to-1-session-cancelled",
    ONE_TO_ONE_SESSION_CANCELLED_BY_EMPLOYEE:
      "employee-1-to-1-session-cancelled-by-employee",
    ONE_TO_ONE_SESSION_CANCELLED_BY_PROFESSIONAL:
      "employee-1-to-1-session-cancelled-by-professional",
    SYMPTOMS_TRACKING_SURVEY_TO_COMPLETE:
      "employee-symptoms-tracking-survey-to-complete",
    ONE_TO_ONE_SESSION_NO_SHOW: "employee-1-to-1-session-no-show",
    ONE_TO_ONE_SESSION_NO_SHOW_EMPLOYEE:
      "employee-1-to-1-session-no-show-employee",
    ONE_TO_ONE_SESSION_PAYMENT_FAILED: "employee-1-to-1-session-payment-failed",
    ONE_TO_ONE_SESSION_REMINDER_24H: "employee-1-to-1-session-reminder-24h",
    ONE_TO_ONE_SESSION_REMINDER_30MIN: "employee-1-to-1-session-reminder-30min",
    ONE_TO_ONE_SESSION_RESCHEDULED: "employee-1-to-1-session-rescheduled",
    ONE_TO_ONE_SESSION_RESCHEDULED_BY_EMPLOYEE:
      "employee-1-to-1-session-rescheduled-by-employee",
    QUESTION_ANSWERED: "employee-question-answered",
    SESSION_REMINDER_24H_CHECK_IN: "employee-session-reminder-24h-check-in",
    SESSION_REMINDER_24H_MANAGER: "employee-session-reminder-24h-manager",
    SIGNUP_INVITATION: "employee-signup-invitation",
    SIGNUP_INVITATION_REMINDER: "employee-signup-invitation-reminder",
    SIGNUP_INVITATION_GUEST: "guest-signup-invitation",
    SIGNUP_INVITATION_GUEST_REMINDER: "guest-signup-invitation-reminder",
    SLACK_INSTALLED: "slackbot-installed",
    CHOOSE_YOUR_THERAPIST: "employee-choose-your-therapist",
    THERAPY_ENDED: "employee-therapy-ended",
    THERAPY_MATCH_SINGLE: "employee-therapy-match-single",
    THERAPY_REMATCH: "employee-therapy-rematch",
    CLASS_OR_TALK_SESSION_REMINDER_24H:
      "employee-class-or-talk-session-reminder-24h",
    CLASS_OR_TALK_SESSION_REMINDER_30MIN:
      "employee-class-or-talk-session-reminder-30min",
    CLASS_OR_TALK_SESSION_BOOKED: "employee-class-or-talk-session-booked",
    CLASS_INVITATION: "employee-invitation-class",
    WORKSHOP_WAITING_LIST_NEW_SESSION:
      "employee-workshop-waiting-list-new-session",
    WORKSHOP_SESSION_CANCELLED: "employee-workshop-session-cancelled",
    WORKSHOP_CANCELLED: "employee-workshop-cancelled",
    WORKSHOP_SESSION_FEEDBACK: "employee-workshop-session-feedback",
    WORKSHOP_FEEDBACK: "employee-workshop-feedback",
    SESSION_SUMMARY_GENERATED: "employee-session-summary-generated",
    PENDING_UNSATISFIED_WITH_PROFESSIONAL_SURVEY:
      "employee-pending-unsatisfied-with-professional-survey",
    PROFESSIONAL_LEFT_OLIVA: "employee-professional-left-oliva",
    NPS_SURVEY_TO_COMPLETE: "employee-nps-survey-to-complete",
    SESSION_ALLOCATION_OVERRIDE: "employee-session-allocation-override",
  },
  PROFESSIONAL: {
    WORKSHOP_CONFIRMED: "mhp-workshop-confirmed",
    WORKSHOP_CANCELLED: "mhp-workshop-cancelled",
    INVITED: "professional-invitation",
    INVITED_REMINDER: "professional-invitation-reminder",
    CANCELLED_SESSION: "mhp-cancelled-session",
    CANCELLED_THERAPY: "mhp-cancelled-therapy",
    CHAT_MESSAGES_PENDING: "professional-chat-messages-pending",
    SESSION_BOOKED: "mhp-session-created",
    SESSION_BOOKED_THERAPY: "mhp-you-got-new-booking-therapy",
    SESSION_REMINDER_30_MIN: "mhp-session-reminder-30min",
    SESSION_RESCHEDULED: "mhp-rescheduled-session",
    THERAPY_CREATED: "mhp-therapy-created",
    CARE_PLAN_SUGGESTION_REMINDER: "mhp-care-plan-suggestion-reminder",
    CARE_PLAN_SUGGESTION_FOLLOWUP_REMINDER:
      "mhp-care-plan-suggestion-follow-up-reminder",
    SESSION_ALLOCATION_EXHAUSTED: "mhp-company-session-allocation-exhausted",
    THERAPY_ENDED_DUE_UNSATISFIED_SURVEY_RECEIVED:
      "mhp-therapy-ended-due-to-satisfaction-survey",
  },
  CARE: {
    LIFE_RISK: "care-employee-at-risk",
    REMATCH_REQUESTED: "care-rematch-requested",
  },
  VIP_TRIAL: {
    TRIAL_INVITATION: "vip-trial-invitation",
  },
};

/**
 * Session reminders constants
 */
export const THIRTY_MIN_REMINDER_MARGIN_IN_MINUTES = 5;
export const REMINDERS_THRESHOLD_IN_HOURS = 24;
export const SESSION_REMINDER_TYPE_TEXT = {
  DROP_IN: "drop in",
  TEST: "test",
  MANAGER_ONE_TO_ONE: "manager 1-to-1",
  THERAPY: "therapy",
};

/**
 * Employee onboarding constants
 */
export const CURRENT_ONBOARDING_VERSION = 1;

/**
 * Engagement reports constants
 */
export const TOPICS_REPORT_MIN_USERS_THRESHOLD = 5;
export const TOPICS_REPORT_MENTIONS_THRESHOLD = 3;

/**
 * Analytics constants
 */
export const ANALYTICS_EMPLOYEE_COMMENTS_MIN_NPS_THRESHOLD = 9; // Between 0 & 10
export const ANALYTICS_MIN_USERS_THRESHOLD = 3;

/**
 * AWS account id
 */
const AWS_ACCOUNT_ID = "628204694153";

const RECORDINGS_CONFIG_PRODUCTION = {
  bucket_name: "daily-recordings-production",
  bucket_region: "eu-west-1",
  assume_role_arn: `arn:aws:iam::${AWS_ACCOUNT_ID}:role/DailyAccessProductionRecordingsRole`,
  allow_api_access: true,
};

const RECORDINGS_CONFIG_STAGING = {
  bucket_name: "daily-recordings-staging",
  bucket_region: "eu-west-3",
  assume_role_arn: `arn:aws:iam::${AWS_ACCOUNT_ID}:role/DailyAccessStagingRecordingsRole`,
  allow_api_access: true,
};

export const RECORDINGS_CONFIG =
  process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
    ? RECORDINGS_CONFIG_PRODUCTION
    : RECORDINGS_CONFIG_STAGING;

export const E2E_ENDPOINTS_KEYPHRASE = "ThisIsNotATokenWhatDoYouMean";
export const E2E_MINUTES_INTO_THE_SESSION = 5;
export const E2E_SESSION_DURATION_HOURS = 1;

// Constants used in the scripts that creates the test users
export const TEST_USER_AUTHORISATION_TOKEN = "spaghetti-giraffe-moon-123";
export const TEST_USER_DEFAULT_PASSWORD = "Oliva!123";

export const UPHEAL_API_URL =
  "https://my2jq4azaj.execute-api.eu-central-1.amazonaws.com/api/v1";
export const SESSION_PROCESSING_RECORDING_LINK_EXPIRATION_SECONDS = 12 * 3600; // 12h

export const SESSION_NOTES_LAST_SAVE_DIFFERENCE_THRESHOLD = 59; // 59 min
export const SESSION_NOTES_LAST_SAVE_REFRESH_FREQUENCY = 60 * 1000; // 60s

export const SECURITY_URL =
  "https://olivahouse.notion.site/Confidentiality-and-security-faf40375f35d4935afbce1ac927d5fad";

export const TRIAGING_ID_LEGACY = "665502490b54c0977f72a2ac"; // TODO: DO NOT REMOVE temporary fix for data tracking during transition from old -> new WBP
export const TRIAGING_VERSION = 6;
export const STRIPE_API_KEY = process.env.STRIPE_API_KEY;
export const NEXT_PUBLIC_STRIPE_PUBLIC_API_KEY =
  process.env.NEXT_PUBLIC_STRIPE_PUBLIC_API_KEY;
export const SESSION_STRIPE_DEFAULT_PRICE_ID =
  ENVIRONMENT === "production"
    ? "price_1NqXFxIC0gTe0BTtYKn5lpoh"
    : "price_1May1ZIC0gTe0BTtoG862XbY";

// This is the price used to charge or refund a user in certain DNA cases
// At the moment it should be 50% of the price of a full session
export const DNA_SESSION_STRIPE_DEFAULT_PRICE_ID =
  ENVIRONMENT === "production"
    ? "price_1POL5KIC0gTe0BTtnzEowSq5"
    : "price_1POL7nIC0gTe0BTt7ZkuKIsp";

export const DATADOG_ALLOWED_ERRORS_REGEX = new RegExp(
  /Command received no ack|Connection is|Too many requests|XHR error POST https:\/\/api\.amplitude\.com/,
);

/**
 * Olivans.
 */
export const ORGANISATION_ID_OLIVANS = "oliva-team";

export const WELLBEING_CHECKIN_BOOK_URL_NOT_OLIVANS =
  "https://calendly.com/wellbeing-check/wellbeing-check";
export const WELLBEING_CHECKIN_BOOK_URL_OLIVANS =
  "https://calendly.com/kate-tilbury/wellbeing-check";

/**
 * Tools constants
 */
export const TOOLS_NEW_TRIAGING_SUBMISSION_DELETE_TOAST_DELAY = 2000; // in ms
export const MEMBERS_INVITATION_TOAST_DELAY = 2000; // in ms
export const SCHEDULE_INVITATION_TIME = 9;
export const TRIAGING_SUBMISSIONS_ITEMS_PER_PAGE = 50;
export const ORGANISATION_ADMIN_MEMBERS_PER_PAGE = 20;
export const DEBOUNCE_DEFAULT_VALUE = 500;

// Video provider minimum participants for handling ending logic
export const VIDEO_PROVIDER_MIN_PARTICIPANTS = 2;

// Minimum number of resources needed for a topic to be recommended
export const MIN_NUMBER_OF_RESOURCES_NEEDED = 3;

export enum CareFormatEnum {
  TEXT = "text",
  VIDEO = "video",
}

// Device notification tokens
export const DEVICE_TOKEN_EXPIRATION_LENGTH_IN_DAYS = 60;

// Calendly supervision booking URL
export const SUPERVISION_SINGLE_BOOKING_URL =
  "https://calendly.com/oliva-supervision";

export const SESSION_ACTIVE_THRESHOLD_IN_MINUTES = 30;
export const CARE_PLAN_REMINDERS_THRESHOLD = {
  MAX_FIRST_REMINDERS: 3,
  MAX_FOLLOW_UP_REMINDERS: 2,
  SESSIONS_NEEDED_FIRST_REMINDER: 3,
  MIN_SESSIONS_NEEDED_FOR_FOLLOW_UP_BANNER: 4,
  SESSIONS_NEEDED_FOLLOW_UP_REMINDER_MILD: 8,
  SESSIONS_NEEDED_FOLLOW_UP_REMINDER_SEVERE: 12,
};

// Mapping of care plan status to the number of sessions
// careStep: [Number of sessions to be completed before progressing to the next step]
export const FOLLOW_UP_BANNER_STATUS_STEPS = {
  0: [4, 6, 8],
  1: [4, 6, 8],
  2: [4, 6, 8],
  3: [4, 6, 8],
  4: [6, 9, 12],
};

export const OLI_UI_FIGMA_URL =
  "https://www.figma.com/file/F5N1ZqT4AmQs5sDM6nm8vS/Oli-Design-System?type=design&";

export const ONE_TO_ONE_SESSION_BOOKING_REMINDER_24_HOURS_TTL_IN_SECONDS =
  25 * 60 * 60;
export const ONE_TO_ONE_SESSION_BOOKING_REMINDER_30_MINS_TTL_IN_SECONDS =
  60 * 60;

// Personal plan organisation id
export const PERSONAL_PLAN_ORGANISATION_ID = "personalplan";

// Metabase embedding resource IDs
export const METABASE_RESOURCE_IDS = {
  SESSION_DISTRIBUTION: 3304,
  SESSION_COMPLETION: 3303,
};
export const JACKSON_PRODUCT = "olivahealth";
export const SSO_INPUT_ID = "sso-login-input";

export const SSO_MULTI_ORG_LOCAL_STORAGE_KEY = "multiOrgSSOOrgId";

export const APP_STORE_ID = "6466439617";
export const PLAY_STORE_ID = "com.olivaHealth.app";

export const TRACK_EVENT_SSO_PROVIDER_NAME = "sso";

// Google play store link
export const PLAY_STORE_LINK = `https://play.google.com/store/apps/details?id=${PLAY_STORE_ID}`;
export const APP_STORE_LINK = `https://apps.apple.com/us/app/oliva/id${APP_STORE_ID}`;

export const FEATURE_FLAGS_DEFAULT_VALUES: FeatureFlags = {
  unifiedProgressCheckSurvey: false,
  profileInsuranceTab: false,
  feature_permissions: {
    chat_as_care_navigator: [],
    chat_as_clinical_supervisor: [],
    chat_as_oliva_customer_support: [],
    admin_tools: [],
  },
};

export const MATCHING_AVAILABILITY_THRESHOLD = 5;

// Surveys
export const SURVEY_EMPLOYEE_UNSATISFIED_QUESTION_ID_MAIN_REASON =
  "main-reason";
export const SURVEY_EMPLOYEE_UNSATISFIED_QUESTION_ID_DESCRIBE_FEEDBACK =
  "description";
export const SURVEY_EMPLOYEE_UNSATISFIED_QUESTION_ID_SHARED_WITH_PROFESSIONAL =
  "shared-with-professional";
export const SURVEY_EMPLOYEE_UNSATISFIED_QUESTION_GROUP_ID_KEEP_OR_REMATCH =
  "keep-or-rematch";
export const SURVEY_EMPLOYEE_UNSATISFIED_QUESTION_ID_KEEP_OR_REMATCH_PROPOSE_KEEP =
  "keep-or-rematch-propose-keep";
export const SURVEY_EMPLOYEE_UNSATISFIED_QUESTION_ID_KEEP_OR_REMATCH_PROPOSE_REMATCH =
  "keep-or-rematch-propose-rematch";
export const SURVEY_EMPLOYEE_UNSATISFIED_QUESTION_VALUE_KEEP_OR_REMATCH_KEEP_CURRENT_PROFESSIONAL =
  "KEEP_CURRENT_PROFESSIONAL";
export const SURVEY_EMPLOYEE_UNSATISFIED_QUESTION_VALUE_KEEP_OR_REMATCH_GET_REMATCHED =
  "GET_REMATCHED";
export const SURVEY_EMPLOYEE_UNSATISFIED_QUESTION_VALUE_KEEP_OR_REMATCH_END_CARE_AND_TAKE_BREAK =
  "END_CARE_AND_TAKE_BREAK";

export const SURVEY_PROFESSIONAL_NPS_COMPLETED_SESSIONS_OVERALL_THRESHOLD = 2;
export const SURVEY_PROFESSIONAL_NPS_COMPLETED_SESSIONS_RECENTLY_PERIOD = 30; // 30 days
export const SURVEY_PROFESSIONAL_NPS_COMPLETED_SUBMISSION_RECENTLY_PERIOD =
  12 * 7; // 12 weeks
export const SURVEY_PROFESSIONAL_NPS_DISMISSED_STORAGE_KEY =
  "surveyProfessionalNPSDismissedAt";
export const SURVEY_PROFESSIONAL_NPS_DISMISSED_PERIOD = 7; // 7 days
export const SURVEY_PROFESSIONAL_NPS_QUESTION_ID_SCORE = "score";
export const SURVEY_PROFESSIONAL_NPS_QUESTION_ID_REASON = "reason";

export const SURVEY_PROGRESS_CHECK_FEEDBACK_ON_PROFESSIONAL_QUESTIONS_IDS = [
  "satisfaction-mhp",
  "satisfaction-progress",
];

export const SURVEY_TRIAL_ONBOARDING_FEEDBACK_RATING =
  "trial-onboarding-feedback-rating";
export const SURVEY_TRIAL_ONBOARDING_FEEDBACK_TEXT =
  "trial-onboarding-feedback-text";

export const SURVEY_TRIAL_FOLLOW_UP_FEEDBACK_RATING =
  "trial-onboarding-follow-up-rating";
export const SURVEY_TRIAL_FOLLOW_UP_FEEDBACK_TEXT =
  "trial-onboarding-follow-up-text";

export const SURVEY_EMPLOYEE_NPS_QUESTION_IDS = {
  RATING: "employee-nps-rating",
  SCORE_REASON: "employee-nps-score-reason",
  NO_MORE_ACCESS: "employee-nps-no-access",
  MORE_LIKELY_TO_STAY: "employee-nps-more-likely-to-stay",
  GO_ABOVE_AND_BEYOND: "employee-nps-go-above-and-beyond",
  FAVOURITE_BENEFIT: "employee-nps-favourite-benefit",
  LEAVE_A_REVIEW: "employee-nps-leave-a-review",
  SUBMIT_FEEDBACK: "employee-nps-submit-feedback",
};

export const SURVEY_EMPLOYEE_NPS_QUESTION_GROUP_ID_REVIEW = "review";
export const SURVEY_EMPLOYEE_NPS_COMPLETED_SUBMISSION_RECENTLY_PERIOD = 12 * 7; // 12 weeks
export const SURVEY_EMPLOYEE_NPS_COMPLETED_SESSIONS_OVERALL_THRESHOLD = 2;
export const SURVEY_EMPLOYEE_NPS_DISMISSED_STORAGE_KEY =
  "surveyEmployeeNPSDismissedAt";
export const EMPLOYEE_REVIEW_LINK =
  "https://olivahouse.notion.site/Help-us-spread-the-word-601c10f0c78646acb526c522712a32dc";
export const SURVEY_EMPLOYEE_NPS_DISMISSED_PERIOD = 7; // 7 d
export const MAX_DAYS_FOR_NEWLY_CREATED_WORKSHOPS = 60;
export const MAX_PARTICIPANTS_FOR_MANAGER_WORKSHOPS = 12;

// Manager workshops surveys and policies
export const WORKSHOP_PRE_SURVEY =
  "https://olivahealth.typeform.com/to/Vm6iwGRa";
export const WORKSHOP_POST_SURVEY =
  "https://olivahealth.typeform.com/to/ovcuUcWm";
export const WORKSHOP_CANCELLATION_BOOKING_POLICY =
  "https://help.olivahealth.app/en/articles/9027474-what-s-the-booking-and-cancellation-policy-for-workshops";

export const EMOJI_REGEX =
  /^[\uD800-\uDFFF][\uDC00-\uDFFF]|[\u2600-\u26FF\u2700-\u27BF]$/;

export const PROGRESS_CHECK_SURVEY_CTA_DELAY = 2; // days
export const VIP_TRIAL_FOLLOW_UP_SURVEY_SESSION_THRESHOLD = 2;

export const PREFERENCE_WEIGHTS = {
  PROFESSIONAL: 0.3,
  LANGUAGE: 0.3,
  GENDER: 0.1,
  PERFORMANCE: 0.1,
  SHARE_OF_WALLET: 0.1,
  TIMEZONE: 0.05,
  SPECIALITY: 0.05,
};

export enum MatchingRuleNames {
  AVAILABILITY_THRESHOLD = "AVAILABILITY_THRESHOLD",
  CAN_PROVIDE_CARE_ON_REGION = "CAN_PROVIDE_CARE_ON_REGION",
  CAN_PROVIDE_CARE_TO_OLIVAN = "CAN_PROVIDE_CARE_TO_OLIVAN",
  CAN_RECEIVE_CLIENTS = "CAN_RECEIVE_CLIENTS",
  CARE_TYPE_EXPERTISE = "CARE_TYPE_EXPERTISE",
  COVERAGE_ANALYSIS = "COVERAGE_ANALYSIS",
  FORCE_COACHING_EXPERTISE = "FORCE_COACHING_EXPERTISE",
  GENDER = "GENDER",
  LANGUAGE = "LANGUAGE",
  TIMEZONE = "TIMEZONE",
  NOT_PREVIOUS_UNSATISFIED_MATCH = "NOT_PREVIOUS_UNSATISFIED_MATCH",
  PERFORMANCE = "PERFORMANCE",
  SHARE_OF_WALLET = "SHARE_OF_WALLET",
  SPECIALITY = "SPECIALITY",
  THERAPY_STEP_EXPERTISE = "THERAPY_STEP_EXPERTISE",
}

export const SENDBIRD_USER_NICKNAME_MAX_LENGTH = 80;

// Wellbeing profile
export const ABOVE_CASENESS_SCORE_THRESHOLD = 55;
export const BELOW_CASENESS_SCORE_THRESHOLD = 70;
export const RECOMMEND_THERAPY_FOCUS_AREAS = ["EMOTIONAL", "PHYSICAL", "SLEEP"];

export const BOOK_SESSION_PATH = "/support?schedule=THERAPY";
