import { CareType } from "@prisma/postgresClient";

export const TherapeuticCareTypes = [
  CareType.THERAPEUTIC_STRUCTURED,
  CareType.THERAPEUTIC_UNSTRUCTURED,
] as CareType[];

export const TherapyCareTypes = [
  ...TherapeuticCareTypes,
  CareType.COACHING,
  CareType.HYBRID_CARE,
] as CareType[];

export const CoachingCareTypes = [CareType.CAREER_COACHING] as CareType[];

export default CareType;
